<template>
  <div>
    <img class="tui-img" v-if="imgList.img" :src="imgList.img" alt="" />
    <div class="bigBox">
      <!-- 搜索 -->
      <div class="searchBox">
        <kind-choose-view :showSchool="true" :showKc="false" :showZy="false" :showLevel="false" :type="null"
          :ifImg="false" @isOk="isOk" />
      </div>
      <!-- 列表 -->
      <div class="courseList">
        <div class="classlist">
          <div class="classlist-item" v-for="(item, index) in list" :key="index">
            <div class="itemmsg">
              <div class="itemimg">
                <img :src="item.pcPictureUrl" alt="" />
              </div>
              <div class="itemtil">
                <div class="itemtil-top">{{ item.name }}</div>
                <div class="itemtil-rq">
                  <i class="rqimg"></i>
                  {{ item.clicks ? item.clicks : 0 }}人气值
                </div>
              </div>
            </div>
            <div class="slott"></div>
            <div class="kbsj">
              <div>开播时间：{{ item.zbBeginTime }}</div>
              <div class="gkhf" v-if="item.state == 2" @click="goDetail(item)">观看回放</div>
              <div v-else @click="goDetail(item)">进入直播</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <el-pagination class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page.pageNum" :page-sizes="[12, 24, 36, 48]" :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="page.total"></el-pagination>
    </div>
  </div>
</template>

<script>
import { getSurfaceList } from '@/api/home'
import KindChooseView from '../../components/kindChooseViews.vue'
import Bus from '@/api/bus'
import Vue from 'vue'
import { getInfo, getDomain } from '@/api/cookies'
import { Know } from '@/api/know.js'
let know = new Know()
export default {
  components: {
    KindChooseView,
  },
  data() {
    return {
      page: {
        pageSize: 12,
        pageNum: 1,
        total: 0,
      },
      list: [], // 课程列表
      currentItem: null, // 选中的公开课
      searchForm: {},
      userInfo: null,
      liveTodayList: [], // 今日直播列表
      zbStartTime: null,
      imgList: {},
      domain: null,
    }
  },
  async created() {
    this.domain = getDomain()
    let date = new Date()
    this.zbStartTime = date.toLocaleDateString()
    this.liveToday()
    this.getList()
    this.userInfo = await getInfo()
    this.getImg()
  },
  methods: {
    mouseover() {
      let cover = document.getElementById('cover')
      cover.style.display = 'flex'
    },
    mouseout() {
      let cover = document.getElementById('cover')
      cover.style.display = 'none'
    },
    isOk(val) {
      this.searchForm.zyId = val.zyId
      this.searchForm.kindId = val.kind
      this.searchForm.schoolId = val.school
      this.getList()
    },
    /* 公开课列表 */
    getList() {
      let data = {
        ...this.page,
        ...this.searchForm,
      }
      delete data.total
      know.getPublicityClassList(data).then((res) => {
        this.list = res.rows

        this.page.total = res.total
      })
    },
    /* 今日直播列表 */
    liveToday() {
      let data = {
        pageSize: 999,
        pageNum: 1,
        zbStartTime: this.zbStartTime,
        zbEndTime: this.zbStartTime,
      }
      know.getPublicityClassList(data).then((res) => {
        if (res.rows.length > 0) {
          this.currentItem = res.rows[0]
        }
        this.liveTodayList = res.rows
        this.liveTodayList.map((item, index) => {
          if (item.state == 1) {
            this.liveTodayList.unshift(this.liveTodayList.splice(index, 1)[0])
          }
        })
      })
    },
    goDetail(item) {
      /* if (item.state == 2 && !item.playbackUrl) {
        this.$message({
          type: "error",
          message: "回放生成中",
        });
      } else {
        this.addRecord(item);
        let routeUrl = this.$router.resolve({
          path: "/publicClass/publicClassDetail",
          query: {
            id: item.id,
          },
        });
        window.open(routeUrl.href, "_blank");
      } */

      if (this.userInfo) {
        if (item.state == 2 && !item.playbackUrl) {
          this.$message({
            type: 'error',
            message: '回放生成中',
          })
        } else {
          this.addRecord(item)
          let routeUrl = this.$router.resolve({
            path: '/publicClass/publicClassDetail',
            query: {
              id: item.id,
              imQunId: item.imQunId,
            },
          })
          window.open(routeUrl.href, '_blank')
        }
      } else {
        Bus.$emit('toLoginPage', true)
      }
    },
    /* 点击公开课添加观看记录 */
    addRecord(item) {
      let data = {
        userId: this.userInfo ? this.userInfo.id : null,
        userName: this.userInfo ? this.userInfo.userName : null,
        classId: item.id,
        port: this.userInfo ? this.userInfo.userSource : null,
      }
      know.addPublicityClassStudentRecord(data).then((res) => { })
    },
    /* 今日直播课程选择 */
    chooseCourse(item) {
      this.currentItem = JSON.parse(JSON.stringify(item))
    },
    getImg() {
      getSurfaceList(4, undefined, this.domain.entrantsType).then((res) => {
        if (res.code == 0) {
          if (res.msg.length > 0) {
            this.imgList = res.msg[0]
          } else {
            this.imgList = {}
          }
        }
      })
    },
    /* 搜索 */
    selectPageList() {
      this.page = {
        pageSize: 12,
        pageNum: 1,
        total: 0,
      }
      this.getList()
    },
    /* 每页条数 */
    handleSizeChange(val) {
      this.page.pageSize = val
      this.page.pageNum = 1
      this.getList()
    },
    /* 当前页码 */
    handleCurrentChange(val) {
      this.page.pageNum = val
      this.getList()
    },
  },
}
</script>

<style lang="less" scoped>
.bigBox {
  width: 1220px;
  height: auto;
  margin: auto;
  margin-top: 24px;

  .searchBox {
    margin-bottom: 40px;
  }

  ::v-deep .zychoice {
    padding-left: 50px;
  }
}

.classlist {
  width: 100%;
  // height: 382px;
  display: flex;
  // justify-content: space-between;
  justify-content: flex-start;
  flex-wrap: wrap;

  >div {
    width: 392px;
    height: 181px;
    background: url('~@/assets/img/homesixth/Group 3327.png');
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    margin-top: 20px;
    margin-right: 22px;
  }

  >div:nth-child(3n) {
    margin-right: 0;
  }

  .classlist-item {
    padding: 16px;

    .itemmsg {
      width: 360px;
      height: 96px;
      display: flex;
      justify-content: flex-start;

      .itemimg {
        width: 144px;
        height: 96px;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;

        img {
          width: 144px;
          height: 96px;
          border-radius: 6px 6px 6px 6px;
          background-size: cover;
        }
      }

      .itemtil {
        position: relative;
        margin-left: 12px;
        margin-top: 10px;

        .itemtil-top {
          width: 192px;
          max-height: 48px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
        }

        .itemtil-rq {
          display: flex;
          height: 12px;
          line-height: 12px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          position: absolute;
          bottom: 10px;

          .rqimg {
            display: block;
            width: 12px;
            height: 12px;
            background: url('~@/assets/img/homesixth/Frame (1).png');
            background-size: cover;
          }
        }
      }
    }

    .slott {
      width: 360px;
      height: 1px;
      background: #ececec;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin-top: 16px;
    }

    .kbsj {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
      height: 28px;
      line-height: 28px;

      >div:nth-child(1) {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }

      >div:nth-child(2) {
        width: 80px;
        height: 28px;
        background: #1061ff;
        border-radius: 14px 14px 14px 14px;
        opacity: 1;
        text-align: center;
        line-height: 28px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        cursor: pointer;
        color: #ffffff;
      }

      .gkhf {
        background: #f5f7f9 !important;
        color: #1061ff !important;
      }
    }
  }
}

/deep/ .el-pagination .active {
  background: #1061ff !important;
}

/deep/ .on {
  background: #ecf2ff !important;
  color: #1061ff !important;
}

.tui-img {
  width: 100%;
  height: 240px;
}</style>
